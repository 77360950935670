.fabContainer{
  
   
    /* bottom: 10px;
    right:20px; */
    margin: auto;
}

.icon{
    position: absolute;
    width: 100%;
    height: 100%;
    
    margin: auto;
    padding-top: 6px;
    align-content: center;
}

.icon p {
   
    position: absolute;
    width:20px;
    height: 20px;
    color: #000;
    background-color: #ffffff;
    border-radius: 100%;
    right: 0;
    top:-20px;
    text-align: center;
    padding-bottom: 2px;
    
}

.test{
   color: #00cdfb75
}