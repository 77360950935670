.hiddenArea {

    width: fit-content;
    height: fit-content;
    border-radius: 32px;
    position: fixed;
    display: block;

    margin-left: -25px;
    align-items: center;
    top: 30vh
}

.visibleTrigger {
    width: fit-content;
    margin: auto;
    
    padding-top: 2px;
    border-radius: 5px;
    background-color: #00cdfb;
}

.hiddenIcons {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 5px;

    border-radius: 5px;
    margin-left: -100px;

}

.test{
    color: #00cdfb5d
}